<template>
  <div class="static-page faq">

    <section class="static-page__hero">
      <div class="static-page__hero-backdrop">
        <img class="static-page__hero-image"  src="assets/images/alpitour/statics/viaggia-con-noi-hero.jpg" alt="viaggia con noi hero" />
      </div>
      <div class="static-page__hero-content">
        <b-container class="d-flex">
          <vb-icon :name="'symbol_vv'" />
          <div class="static-page__hero-heading">
            <h1 class="vb-heading" v-html="'<span>Per prenotare un pacchetto con '+ site.brand + ' ' + site.payoff +'</span><br><span>bastano pochi click</span>'" />
          </div>
        </b-container>
      </div>
    </section>

    <section class="section">
      <b-container>
        <p class="mb-5" v-html="'Il servizio è dedicato ad utenti titolari di <strong>crediti di welfare aziendale</strong> ed è disponibile sui più importanti portali di welfare aziendale italiani. Contatta l’ufficio personale della tua azienda o l’assistenza del tuo portale welfare per sapere se Vacanze by VadoBay è stato attivato.'" />
        <h2 class="vb-heading h1" v-html="'Domande frequenti'" id="domande-frequenti" />
      </b-container>
    </section>

    <section class="section" id="prenota-un-viaggio">
      <b-container>
        <h3 v-html="'Come posso <span class=\'text-secondary\'>prenotare</span> un viaggio?'" />
        <ul>
          <li v-html="'Per prenotare un viaggio devi accedere al <strong>portale welfare</strong> della tua azienda, cercare il servizio <strong>' + site.brand + ' ' + site.payoff + '</strong> e accedere al nostro sito.'" />
          <li v-html="'A quel punto potrai prenotare un pacchetto viaggio in decine e decine di destinazioni.'" />
        </ul>
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <value-proposition
            :vp="[
                  {
                    vp_image: 'assets/images/alpitour/statics/viaggia-con-noi-by-vadobay.jpg',
                    vp_content: [
                      {
                        vp_icon: 'symbol_vv',
                        vp_title: '<span>Cosa posso prenotare su ' + site.brand + ' ' + site.payoff + '?</span>',
                        vp_text: 'Puoi prenotare <strong>pacchetti viaggio</strong> di alcuni tra i più importanti tour operator italiani quali <strong>Alpitour, Turisanda, Eden Viaggi, Francorosso e Bravo.</strong> Tutti i pacchetti sono comprensivi di <strong>pernotto</strong> e <strong>biglietto aereo.</strong> Il pernotto può includere anche <strong>varie tipologie di trattamento.</strong> I voli sono principalmente <strong>voli charter</strong> ma alcuni pacchetti includono servizi di <strong>trasporto di linea.</strong>',
                      }
                    ]
                  }
                ]"
        />
        <p class="mt-4" v-html="'Ti ricordiamo anche che non è possibile prenotare viaggi personalizzati, ma solo pacchetti viaggio come definiti dai vari tour operator. Sono disponibili varianti di trattamento, dalla colazione all’all inclusive, dalla mezza pensione alla pensione completa, camere di diverso livello, voli economy e voli premium ma tutte queste opzioni possono essere selezionate solo tramite il sito.'" />
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 v-html="'Quali <span class=\'text-secondary\'>bagagli</span> sono inclusi nei biglietti aerei?'" />
        <p v-html="'I biglietti aerei includono sempre il trasporto di un bagaglio a mano e di un bagaglio in stiva per ogni adulto e per ogni bambino partecipanti al viaggio. Per le specifiche franchigie contatta la nostra assistenza una volta individuato il pacchetto che ti interessa.'" />
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 v-html="'Sono richiesti <span class=\'text-secondary\'>visti e/o passaporti o vaccinazioni</span> obbligatorie?'" />
        <p v-html="'Questo dipende dalla destinazione del tuo viaggio. Ti invitiamo a visitare il sito <a href=\'https://www.viaggiaresicuri.it\' target=\'_blank\'>Viaggiaresicuri.it</a> del Ministero degli esteri italiano ed, eventualmente, a contattare l\'ambasciata o il consolato del Paese di destinazione del tuo viaggio per avere maggiori informazioni.'" />
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 v-html="'Cosa include l\'<span class=\'text-secondary\'>assicurazione medico bagaglio</span>?'" />
          <services-insurance class="services-insurance" />
      </b-container>
    </section>

    

    <section class="section">
      <b-container>
        <div class="static-page__quote">
          <vb-icon :name="'quote'" :color="'f8af3c'" />
          <h3 class="static-page__quote-title h1" v-html="'Come faccio ad accedere alle proposte ' + site.brand + ' ' + site.payoff + '?'" />
          <vb-icon :name="'quote'" :color="'f8af3c'" />
        </div>
        <p v-html="'Per accedere ai nostri servizi online bisogna effettuare login sul portale welfare della tua azienda, cercare ' + site.brand + ' ' + site.payoff + ' e accedere al nostro sito. Contatta il tuo ufficio risorse umane o il tuo portale di welfare aziendale per avere maggiori informazioni su come fruire dei nostri servizi.'" />
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <h3 v-html="'Cosa può offrirti <span class=\'text-secondary\'>l’assistenza</span> di Vadobay?'" />
        <ul>
          <li v-html="'<strong>Consigli sulle destinazioni.</strong> Contattaci tramite uno dei nostri canali che trovi in fondo a questa pagina e cercheremo di fare il possibile per aiutarti a trovare la vacanza dei tuoi sogni.<br>Ti ricordiamo che la prenotazione deve essere fatta in autonomia sul nostro sito, non ci è quindi possibile prenotare per te.'" />
          <li v-html="'<strong>Invio dei documenti di viaggio</strong> pochi giorni prima della partenza.<br>Se necessiti di informazioni specifiche prima del ricevimento dei documenti di viaggio non esitare a contattare la nostra assistenza.'" />
        </ul>
      </b-container>
    </section>

    <section class="section" id="credito-non-sufficiente">
      <b-container>
        <div class="static-page__quote">
          <vb-icon :name="'quote'" :color="'f8af3c'" />
          <h3 class="static-page__quote-title vb-heading h1" v-html="'Credito non sufficiente? Contattaci'" />
          <vb-icon :name="'quote'" :color="'f8af3c'" />
        </div>
        <p v-html="'Se il tuo credito welfare non è sufficiente puoi <a href=\'javascript:window.zE.activate()\'>contattarci</a>. Troveremo, tramite i nostri consulenti, una soluzione che passi tramite la prenotazione di alcuni servizi con il credito disponibile e altri servizi separatamente.'" />
      </b-container>
    </section>

    <section class="section">
      <div class="static-page__form" id="contattaci">
        <b-container>
          <form-contatti
              :formTitle="title_request_form"
              :formSubTitle="subtitle_request_form"
              :label="'form-contatti-viaggia-con-noi'"
              :recipient="config.site.emailSupport"
          />
      </b-container>
      </div>
    </section>

  </div>
</template>


<script>
import Vue from "vue";
import FormContatti from '@components/blocks/form-contatti.vue'
import ValueProposition from '@library/blocks/value-proposition.vue'
import ServicesInsurance from '@alpitour/components/blocks/detail/assicurazione-medico-bagaglio.vue'

export default {
  name: 'viaggia-con-noi',
  components:{
    'form-contatti': FormContatti,
    'value-proposition': ValueProposition,
    'services-insurance': ServicesInsurance,
  },
  props: {
  },
  created() {
  },
  data () {
    return {
      config: Vue.prototype.$config,
      site: Vue.prototype.$config.site,
      title_request_form: 'Hai bisogno di <span class=\'text-secondary\'>maggiori informazioni?</span> Contattaci!',
      subtitle_request_form: 'Compila il seguente form oppure scrivi una mail a <a href=\'mailto:' + Vue.prototype.$config.site.emailSupport + '\'>' + Vue.prototype.$config.site.emailSupport + '</a>',
    }
  },
  computed: {
  },
}
</script>


<style lang="scss">
.faq {
  .services-insurance {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    h2 {
      display: none !important;
    }
  }
}
</style>